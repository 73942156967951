import { AxiosResponse } from 'axios';
import { IError, IRequestOption, IResponse } from './httpRequest';

// import { routerStore } from 'stores'

/**
 * 默认的http请求配置， 默认显示错误信息提示
 */
export const DEFAULT_HTTP_OPTION: IRequestOption = {
    withCredentials: false,
    isSuccess: (res: IResponse) =>
        res.status !== undefined && res.status !== null && Number(res.status) === 1,

    requestBodyFormatter: (data: IPlainObject) => {
        return data;
    },
    responseFormatter: (res: AxiosResponse<any>) => {
        return {
            status: res.data.code,
            data: res.data.response || res.data.data,
            msg: res.data.msg || '',
        };
    },
    onError: async (err: IError) => {
        console.error(err);
    },
};
