import React, { useState, useEffect } from "react";
import defaultImg from "assets/image/default.jpg";
import styles from "./detail.module.css";
import { Card, Item } from "./Search";
import { getAntiFakeGoodsInfo } from "services";
import ReactLoading from "react-loading";

const Detail = () => {
  document.title = "产品详情";
  const [Data, setData] = useState<IPlainObject>({});
  const [loading, setLoading] = useState(true);
  const imgerrorfun = (event: any) => {
    // 只调用一次onError 防止默认图加载不出来导致死循环
    event.target.src = defaultImg
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: IPlainObject = await getAntiFakeGoodsInfo();
        setData(res);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return loading ? (
    <div className={styles.loadingWrap}>
      <ReactLoading type="bars" color="#e31673" width="50%" height="50%" />
    </div>
  ) : (
    <section>
      <div className={styles.imageContainer}>
        <img src={Data.img_url || defaultImg} alt="图片加载失败" onError={imgerrorfun} width={186} height={186} />
        <div style={{ textAlign: "left", padding: 15, fontWeight: 500 }}>
          {Data.goods_name || "--"}
        </div>
      </div>
      <Card title="备注">
        <div style={{ padding: "12px 0" }}>
          <Item label=""> {Data.comments || "--"}</Item>
        </div>
      </Card>
    </section>
  );
};

export default Detail;
