import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Views from 'Views';

const App = () => {
    return (
        <div style={{ background: '#f5f5f5', height: '100%', position: 'relative' }}>
            <Router>
                <Switch>
                    <Route path='/' exact component={Views} />
                </Switch>
            </Router>
        </div>
    );
};

export default App;
