import env from 'env';
import http from './httpRequest';

import { DEFAULT_HTTP_OPTION } from './conf';

const { get } = http;

export const getAntiFakeInfo = (from_info: string) => {
    const params = {
        url: window.location.href,
        channel_type: 'X',
        from_info,
        web_browser: navigator.userAgent,
        api: 'fake-info',
    };
    return get!(env.apiServe, params, DEFAULT_HTTP_OPTION);
};

export const getAntiFakeGoodsInfo = () => {
    const params = {
        url: window.location.href,
        api: 'goods-info',
    };
    return get!(env.apiServe, params, DEFAULT_HTTP_OPTION);
};