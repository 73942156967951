import React, { useEffect, useState } from "react";
import styles from "./search.module.css";
import { getAntiFakeInfo } from "services";
import ReactLoading from "react-loading";

const Search = () => {
  document.title = "查询详情";
  const [Data, setData] = useState<IPlainObject>({});
  const [loading, setLoading] = useState(true);

  function success(pos: { coords: any }) {
    var crd = pos.coords;
    const fetchData = async () => {
      try {
        const res: IPlainObject = await getAntiFakeInfo(`${crd.latitude},${crd.longitude}`);
        setData(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
    console.log("Your current position is:");
    console.log("Latitude : " + crd.latitude);
    console.log("Longitude: " + crd.longitude);
    console.log("More or less " + crd.accuracy + " meters.");
  }

  function replyTelNo(text: string) {
    if (Data.ProductionOrgName.indexOf("广州四三三四") > -1) {
      const strs = text.split("咨询热线");
      if (strs.length < 2) {
        return text;
      } else {
        return strs[0] + "咨询热线020-39993477。谢谢您的使用！";
      }
    } else {
      return text;
    }
  }

  // function error(err: { code: string | number; message: string }) {
  //   window.alert(err.message);
  //   console.warn("ERROR(" + err.code + "): " + err.message);
  // }
  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(success, error, options);
    const pos = {
      coords: {
        latitude: '0.00',
        longitude: '0.00',
      }
    };
    success(pos);
  }, []);

  return loading ? (
    <div className={styles.loadingWrap}>
      <ReactLoading type="bars" color="#e31673" width="50%" height="50%" />
    </div>
  ) : (
    <section>
      <div className={styles.head}>该身份码当前为第{Data.QueryCount}次查询</div>
      <Card title="产品身份证信息">
        <div className={styles.idCardInfo}>
          <span style={{ color: "#666666" }}>产品唯一身份编码</span>
          <br />
          <span>{Data.Code || "--"}</span>
        </div>
        <div className={styles.idCardInfo}>
          <span style={{ color: "#666666" }}>第一次查询时间</span>
          <br />
          <span>{Data.FirstQueryTime || "--"}</span>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.footer}>{replyTelNo(Data.ReplyContent || "--")}</div>
      </Card>
      <Card title="产品信息">
        <div style={{ padding: "12px 0" }}>
          <Item label="企业名称">{Data.ProductionOrgName || "--"}</Item>
          <Item label="产品名称">{Data.ProductName || "--"}</Item>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.footer}>{Data.comments || "--"}</div>
      </Card>
    </section>
  );
};

export const Card = (props: { title: string; children?: React.ReactNode }) => {
  return (
    <div className={styles.cardWarp}>
      <div className={styles.cardTitle}>
        <div className={styles.vertical} />
        <div>{props.title}</div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export const Item = (props: { label: string; children?: React.ReactNode }) => {
  return (
    <div className={styles.item}>
      <div className={styles.label}>{props.label}</div>
      <div>{props.children}</div>
    </div>
  );
};

export default Search;
