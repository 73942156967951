import React from 'react';
import Detail from './Detail';
import Search from './Search';
import { RouteComponentProps } from 'react-router-dom';

const viewsMap = {
    detail: Detail,
    search: Search,
};

const Views = (props: RouteComponentProps) => {
    const {
        location: { search },
    } = props;
    const searchParams = search.substring(1);
    const type = judgeType(searchParams);
    const Comp = viewsMap[type];
    return <Comp />;
};

function judgeType(props: string) {
    const len = props.length;
    
    switch (len) {
        case 19:
            return 'detail';
        case 23:
            return 'search';
        default:
            return 'search';
    }
}

export default Views;
