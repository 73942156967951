import devConfig from './dev';
import prodConfig from './prod';
import testConfig from './test';

const APP_ENV = process.env.REACT_APP_ENV;

let config: IPlainObject = {};

if (APP_ENV === 'dev') {
    config = devConfig;
} else if (APP_ENV === 'prod') {
    config = prodConfig;
} else if (APP_ENV === 'test') {
    config = testConfig;
} else {
    config = devConfig;
}

export default config;
